import Domain from "../domain";
export default {
  methods: {
    getOrderSummary: async function(from, to, store) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/report?order_status=30&order_status=40&order_status=41&order_status=50&store=" + store + "&from_date=" + from + "&to_date=" + to,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderSummaryWithOutStore: async function(from, to) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/report?order_status=30&order_status=40&order_status=41&order_status=50&from_date=" + from + "&to_date=" + to,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderSummaryByOder: async function(from, to, store) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/group/report?order_status=30&order_status=40&order_status=41&order_status=50&store=" + store + "&from_date=" + from + "&to_date=" + to,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderSummaryByOderWithOutStore: async function(from, to) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/group/report?order_status=30&order_status=40&order_status=41&order_status=50&from_date=" + from + "&to_date=" + to,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProductSummary: async function(from, to, store) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/sold/report?order_status=30&order_status=40&order_status=41&order_status=50&store=" + store + "&from_date=" + from + "&to_date=" + to,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProductSummaryWithOutStore: async function(from, to) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/sold/report?order_status=30&order_status=40&order_status=41&order_status=50&from_date=" + from + "&to_date=" + to,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
}