<template>
<div class="merchreportlist">
  <div class="row">
    <div class="col-12 ml-5 well" style="border: none;">
      <h2 class="title">Order Summary(by line wise)</h2>

      <div class="row" style="margin-left: 0px; margin-right: 0px;">
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 from-to-date" style="padding-right: 0px; padding-left: 0px; margin-right: 10px">
          <label>From</label>
          <datepicker v-model="osFilterDatefrom" :disabledDates="disabledOsFilterDatefrom" @selected="setdisablOsFilterDateto()" placeholder="Select Date"></datepicker>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 from-to-date" style="padding-right: 0px; padding-left: 0px;">
          <label>To</label>
          <datepicker v-model="osFilterDateto" :disabledDates="disabledOsFilterDateto" placeholder="Select Date"></datepicker>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12" style="padding-right: 0px; padding-left: 0px; margin-top: 30px; margin-left: 5px">
          <b-button style="border-radius: 3px;" class="btn merchprof-sbtn" variant="primary" @click="haddleOrderSummary()">Generate</b-button>
        </div>
      </div>

    </div>

    <div class="col-12 ml-5 well" style="border: none;">
      <h2 class="title">Order Summary(by order wise)</h2>

      <div class="row" style="margin-left: 0px; margin-right: 0px;">
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 from-to-date" style="padding-right: 0px; padding-left: 0px; margin-right: 10px">
          <label>From</label>
          <datepicker v-model="osFilterDatefromByOrder" :disabledDates="disabledOsFilterDatefromByOrder" @selected="setdisablOsFilterDatetoByOrder()" placeholder="Select Date"></datepicker>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 from-to-date" style="padding-right: 0px; padding-left: 0px;">
          <label>To</label>
          <datepicker v-model="osFilterDatetoByOrder" :disabledDates="disabledOsFilterDatetoByOrder" placeholder="Select Date"></datepicker>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12" style="padding-right: 0px; padding-left: 0px; margin-top: 30px; margin-left: 5px">
          <b-button style="border-radius: 3px;" class="btn merchprof-sbtn" variant="primary" @click="haddleOrderSummaryByOrder()">Generate</b-button>
        </div>
      </div>
      
    </div>

  </div>
  <div class="row">
    <div class="col-12 ml-5 well" style="border: none;">
      <h2 class="title">Product Summary</h2>

      <div class="row" style="margin-left: 0px; margin-right: 0px;">
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 from-to-date" style="padding-right: 0px; padding-left: 0px; margin-right: 10px">
          <label>From</label>
          <datepicker v-model="psFilterDatefrom" :disabledDates="disabledPsFilterDatefrom" @selected="setdisablepsFilterDateto()" placeholder="Select Date"></datepicker>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 from-to-date" style="padding-right: 0px; padding-left: 0px;">
          <label>To</label>
          <datepicker v-model="psFilterDateto" :disabledDates="disabledPsFilterDateto" placeholder="Select Date"></datepicker>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-12" style="padding-right: 0px; padding-left: 0px; margin-top: 30px; margin-left: 5px">
          <b-button style="border-radius: 3px;" class="btn merchprof-sbtn" variant="primary" @click="haddleProductSummary()">Generate</b-button>
        </div>
      </div>

    </div>
  </div>
</div>  
</template>
<script>
import Datepicker from "vuejs-datepicker";
import mixinReport from "../../../mixins/merchant/APIReport";
export default {
  components: {
    Datepicker,
  },
  mixins: [mixinReport],
  data: function() {
    return {
      breadcrumb: [
        {
          text: "Reports",
          href: "/merchant-report-list",
          active: true,
        }
      ],
      osFilterDatefrom: new Date(),
      osFilterDateto: new Date(),

      psFilterDatefrom: new Date(),
      psFilterDateto: new Date(),

      osFilterDatefromByOrder: new Date(),
      osFilterDatetoByOrder: new Date(),

      disabledOsFilterDatefrom: {
        from: new Date()
      },
      disabledOsFilterDateto: {
        from: new Date()
      },

      disabledPsFilterDatefrom: {
        from: new Date()
      },
      disabledPsFilterDateto: {
        from: new Date()
      },

      disabledOsFilterDatefromByOrder: {
        from: new Date()
      },
      disabledOsFilterDatetoByOrder: {
        from: new Date()
      },

      storeId: '-1'
    }
  },
  computed: {
    
  },
  watch: {
    "$store.state.merchant_selectedstore": function(val) {
      this.storeId = val;
    },
    osFilterDatefrom: function(val) {
     this.disabledOsFilterDateto = {
          from: new Date(),
          to: new Date(val)
      };
    },
    psFilterDatefrom: function(val) {
      this.disabledPsFilterDateto = {
          from: new Date(),
          to: new Date(val)
      };
    },
    osFilterDatefromByOrder: function(val) {
      this.disabledOsFilterDatetoByOrder = {
          from: new Date(),
          to: new Date(val)
        };
    }

  },
  created() {
    this.handleBreadcrumb();
  },
  methods: {
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    setdisablOsFilterDateto() {
      this.osFilterDateto = null;
    },
    setdisablOsFilterDatetoByOrder() {
      this.osFilterDatetoByOrder = null;
    },
    setdisablepsFilterDateto() {
      this.psFilterDateto = null;

    },
    haddleOrderSummary: async function() {
      this.osFilterDatefrom = moment(this.osFilterDatefrom).format("YYYY-MM-DD");
      this.osFilterDateto = moment(this.osFilterDateto).format("YYYY-MM-DD");
      try {
        if (this.storeId === '-1') {
          let res = await this.getOrderSummaryWithOutStore(this.osFilterDatefrom, this.osFilterDateto);
          let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http:///3.136.49.247:3049/" + res.url; // test
          window.open(newUrl);
        } else {
          let res = await this.getOrderSummary(this.osFilterDatefrom, this.osFilterDateto, this.storeId);
          let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http:///3.136.49.247:3049/" + res.url; // test
          window.open(newUrl);
        } 
      } catch (error) {
        throw error;
      }
    },
    haddleOrderSummaryByOrder: async function() {
      this.osFilterDatefromByOrder = moment(this.osFilterDatefromByOrder).format("YYYY-MM-DD");
      this.osFilterDatetoByOrder = moment(this.osFilterDatetoByOrder).format("YYYY-MM-DD");
      try {
        if (this.storeId === '-1') {
          let res = await this.getOrderSummaryByOderWithOutStore(this.osFilterDatefromByOrder, this.osFilterDatetoByOrder);
          let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http:///3.136.49.247:3049/" + res.url; // test
          window.open(newUrl);
        } else {
          let res = await this.getOrderSummaryByOder(this.osFilterDatefromByOrder, this.osFilterDatetoByOrder, this.storeId);
          let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http:///3.136.49.247:3049/" + res.url; // test
          window.open(newUrl);
        } 
      } catch (error) {
        throw error;
      }
    },
    haddleProductSummary: async function() {
      this.psFilterDatefrom = moment(this.psFilterDatefrom).format("YYYY-MM-DD");
      this.psFilterDateto = moment(this.psFilterDateto).format("YYYY-MM-DD");
      try {
        if (this.storeId === '-1') {
          let res = await this.getProductSummaryWithOutStore(this.psFilterDatefrom, this.psFilterDateto);
          let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http:///3.136.49.247:3049/" + res.url; // test
          window.open(newUrl);
        } else {
          let res = await this.getProductSummary(this.psFilterDatefrom, this.psFilterDateto, this.storeId);
          let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http://124.43.130.173:3063/" + res.url;
          // let newUrl = "http:///3.136.49.247:3049/" + res.url; // test
          window.open(newUrl);
        }
      } catch (error) {
        throw error;
      }
    }
  }
}
</script>
<style>
.from-to-date .vdp-datepicker input {
  width: 100% !important;
}
.vdp-datepicker input {
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
.merchreportlist .merchprof-sbtn{
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchreportlist .merchprof-sbtn:hover {
  color: #000000;
}
</style>

